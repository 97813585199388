import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { navActions } from '../store/nav'

import datas from '../datas/navigation.json'
import { MenuStyles } from './MenuStyles'

const MenuLink = ({ pageSlug }) => {
  const active = useSelector(state => state.nav.active)
  const dispatch =  useDispatch()

  const onLinkHandler = () => {
    dispatch(navActions.toggle())
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }

  return (
    <MenuStyles active={active}>
      {datas.map((page, index) => {
        return (
          <li key={`${pageSlug}-${index}`} >
            <Link to={`/${page.slug}`} onClick={onLinkHandler}>
              {page.title}
            </Link>
            {page.subpages &&
            <ul>
              {page.subpages.map((subpage, index) =>
              (
              <li key={`${pageSlug}-${index}`}>
                <Link to={`/${page.slug}/${subpage.slug}`} onClick={onLinkHandler}>
                  {subpage.title}
                </Link>
              </li>
              ))}
            </ul>
            }
          </li>
        )
      })}
      <li>
        <Link to="/homepage" onClick={onLinkHandler}>Back to home</Link>
      </li>
  </MenuStyles>
  )
}

export default MenuLink
