import { useRef } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import Header from '../components/Header'
import MenuScroll from '../components/MenuScroll'
import Content from '../components/Content'
import TitleMain from '../components/TitleMain'
import Card from '../components/Card'
import Footer from '../components/Footer'

const PageScroll = ({ datas, withPadding, classes = "", children }) => {
  const sectionRefs = useRef([])

  const onLinkHandler = () => {
		window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }

  return (
    <div className={classes}>
      <Header>
        <MenuScroll refs={sectionRefs} />
      </Header>
      <Content withPadding={withPadding}>
        {/* <TitleBigStyles>I am a title</TitleBigStyles> */}
        <TitleMainStyles>Draw your path...</TitleMainStyles>
        {/* <TitleBigStyles>Discoveries, curiosity and experience shape our path</TitleBigStyles> */}
        {datas.map((el, i) => {
          return (
            <Section
            ref={(el) => (sectionRefs.current[i] = el)}
            key={`${classes}-section-${i}`}
            >
              <Link to={`${el.page_link}`} onClick={onLinkHandler}>
                <Card card={el} right={i%2 ? true : false}/>
              </Link>
            </Section>
          )
        })}
        {children}
      </Content>
      <Footer />
    </div>
  )
}

const Section = styled.section`
  position: relative;
  scroll-margin: 80px;
  margin-bottom: 2rem;
`
const TitleMainStyles = styled(TitleMain)`
  scroll-margin: 80px;
`

export default PageScroll
