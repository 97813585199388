import styled from 'styled-components'
import { breakpoints } from '../styles/devices'

const Grid = ({children}) => {
  if(!children) return <Text>Pictures are coming soon...</Text>

  return (
    <GridStyles className="grid-photos">
      {children}
    </GridStyles>
  )
}

const GridStyles = styled.div`
  display: grid;
  grid-gap: 1rem;
  margin-top: 50px;

  .box {
    position: relative;

    img {
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
    }

    .comment {
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      height: 99%; //@TODO to correct
      /* max-height: 50%; */
      padding: 20px;
      font-size: 0;
      text-align: center;
      background-color: rgba(255, 255, 255, 0);
      transition: background-color 0.5s;

    }

    &:hover .comment {
      display: flex;
      flex-direction: column;
      font-size: 16px;
      background-color: rgba(255, 255, 255, 0.9);

      h3 {
        font-size: 1.25rem;
        text-transform: capitalize;
        font-weight: 300;
      }

      .date {
        letter-spacing: 0.5px;
      }

      .label {
          font-style: italic;
          font-size: 0.6rem;
      }
      .credit {
        font-size: 0.8rem;
      }
    }
  }

  @media ${breakpoints.mobileL} {
    grid-template-columns: 1fr 1fr;
  }

  @media ${breakpoints.tablet} {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    max-width: 100%;
  }
`

const Text = styled.p`
  font-size: 1.25rem;
  text-align: center;
`

export default Grid
