import styled from 'styled-components'
import { breakpoints } from '../styles/devices'
import { menuAnimation } from '../styles/animation'

export const MenuStyles = styled.ul`
  display: ${({active}) => active ? 'flex' : 'none'};
  flex-direction: column;
  padding-top: 10%;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: ${({theme}) => theme.fontSizes.xxl};
  color: ${({theme}) => theme.colors.white100};
  background: rgba(0, 0, 0, 0.9);
  animation: ${menuAnimation} 1s;

  > li {
    padding: 0.75rem;

    ul li {
      font-size: 1rem;
      color: white;
    }
  }

  @media ${breakpoints.tablet} {
    padding-top: 8%;
    height: 100vh;
  }
`
