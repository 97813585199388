import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'

import { navActions } from '../store/nav'
import * as animations from '../styles/animation'

const NavBurger = () => {
	const dispatch = useDispatch()
	const active = useSelector(state => state.nav.active)
	const onClickHandler = () => {
		dispatch(navActions.toggle())
	}

	const labelStyles = active ? 'inner active' : 'inner'

	return (
		<Burger>
			<div onClick={onClickHandler} className={labelStyles}>
				<span></span>
			</div>
		</Burger>
	)}

export default NavBurger


const Burger = styled.div`
		display: flex;
		justify-content: flex-end;
		padding: 1rem;
		z-index: 9999;

		.inner{
			position: relative;
			width: 30px;
			height: 30px;
			display: block;
			cursor: pointer;

			&:before,
			&:after {
				content: '';
				position: absolute;
				top: 50%;
				margin-top: -2px;
				width: 30px;
				height: 3px;
				border-radius: 2px;
				background: #484848;
				transition: background 0.5s;
			}

			&:before {
				animation: ${animations.animationOneReverse} 1s ease forwards;
			}
			&:after {
				animation: ${animations.animationTwoReverse} 1s ease forwards;
			}

			&:hover {
				&:before,
				&:after {
					background: #c6c5c5;
				}
				span {
					&:before,
					&:after {
						background: #c6c5c5;
					}
				}
			}

			span {
				&:before,
				&:after {
					content: '';
					position: absolute;
					width: 30px;
					height: 3px;
					border-radius: 2px;
					background: #484848;
					transition: background 0.5s;
				}

				&:before {
					top: 4px;
					animation: ${animations.animationBurgerTopReverse} 1s ease forwards;
				}
				&:after {
					top: initial;
					bottom: 5px;
					animation: ${animations.animationBurgerBottomReverse} 1s ease forwards;
				}
			}
		}

		.active {
			&:before,
			&:after {
				background: #9f9f9f;
				transition: background 0.5s;
			}
			&:before {
				animation: ${animations.animationOne} 1s ease forwards;
			}
			&:after {
				animation: ${animations.animationTwo} 1s ease forwards;
			}
			span {
				&:before,
				&:after {
					background: #9f9f9f;
					transition: background 0.5s;
				}
				&:before {
					animation: ${animations.animationBurgerTop} 1s ease forwards;
				}
				&:after {
					animation: ${animations.animationBurgerBottom} 1s ease forwards;
				}
			}
		}
	}
`
