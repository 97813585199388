export const theme = {
	fontFamily: {
		"base": "Oswald",
		// "title": "GreatVibes",
		// "titleAlt": "FjallaOne"
	},
	baseFontSize: "16px",
	fontSizes: {
		"xs": ".625rem",
		"sm": ".875rem",
		"md": "1rem",
		"lg": "1.25rem",
		"xl": "1.5rem",
		"xxl": "1.75rem",
		"xxxl": "2rem",
		"xxxxl": "2.25rem"
	},
	titles: {
		"xxs": "1.5rem",
		"xs": "1.75rem",
		"sm": "2rem",
		"md": "2.5rem",
		"lg": "3rem",
		"xl": "3.5rem",
		"xxl": "4rem",
		"xxxl": "5rem"
	},
	colors: {
		// "main": "#10aab7",
		// "main50": "#87d4da",
		// "main30": "#b7e5e9",
		// "main20": "#cfeef1",
		// "main10": "#E7F7F8",
		// "secondary": "#85bB3f",
		// "secondary50": "#c2dd9f",
		// "secondary30": "#daeBc5",
		// "secondary20": "#e7f1d9",
		// "secondary10": "#E7F7F8",
		"white100": "#fff",
		"black": "#000",
		// "grey100": "#1b2324",
		// "grey90": "#313839",
		// "grey80": "#484e4f",
		// "grey70": "#5f6565",
		// "grey60": "#767b7b",
		// "grey50": "#8d9191",
		// "grey40": "#a3a7a7",
		// "grey30": "#babdbd",
		// "grey20": "#d1d3d3",
		// "grey10": "#e8e9e9",
		// "grey5": "#f3f4f4",
		// "mainBg": "#fcfcfc",
		// "inputBg": "#f9f9f9",
		// "inputBgDisabled": "#f1f1f1",
		"success": "#10aab7",
		"error": "#ff4b23",
		// "discount": "#2cba5b"
	},
	spaces: {
		"xs": "5px",
		"sm": "10px",
		"md": "15px",
		"lg": "20px",
		"xl": "25px",
		"xxl": "30px",
		"xxxl": "50px"
	},
	header: {
		"height": 150,
		"minHeight": 100
	},
	content: {
		"spaceXs": "15px",
		"spaceSm": "30px",
		"spaceMd": "50px",
		"spaceLg": "70px",
		"spaceXl": "100px",
		"spaceXxl": "120px"
	},
	boxShadows: {
		"normal": "0 2px 4px rgba(0, 0, 0, 0.1)",
		"light": "0 2px 4px rgba(0, 0, 0, 0.05)",
		"thin": "1px 1px 2px rgba(0, 0, 0, 0.1)",
		"large": "1px 3px 10px rgba(0, 0, 0, 0.2)",
		"input": "5px 5px 7px rgba(0, 0, 0, 0.05), -5px -5px 7px #fff",
		"inputInset": "inset 5px 5px 7px rgba(0, 0, 0, 0.05), inset -5px -5px 7px #fff",
		"lightInput": "3px 3px 5px rgba(0, 0, 0, 0.05), -3px -3px 5px #fff"
	},
	borderRadius: {
		"xs": "2px",
		"md": "8px",
		"xl": "16px"
	}
}
