import styled from 'styled-components'
import { breakpoints } from '../styles/devices'


const TitleMain = ({ children }) => {
	return (
		<Wrapper>
			<h2>{children}</h2>
		</Wrapper>
		)
}

const Wrapper = styled.div`
  position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 20vh;
	font-weight: 100;
	font-size: 1.75rem;


	@media ${breakpoints.tablet} {
		font-size: 2.5rem;
	}
`

export default TitleMain
