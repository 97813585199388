import { useState, useEffect } from 'react'
import styled from 'styled-components'
import { breakpoints } from '../styles/devices'

const Donate = () => {
	const [active, setActive] = useState(false)

	useEffect(() => {
		const timer = setTimeout(() => {
			setActive(true)
		}, 4000)

		return () => {clearTimeout(timer)}
	}, [])

	const onCloseHandler = () => {
		setActive(false)
	}

	return (
		<Wrapper active={active}>
			<CloseButton onClick={onCloseHandler}></CloseButton>
				<form action="https://www.paypal.com/donate" method="post" target="_top">
					<input type="hidden" name="business" value="RPSL5G8EAKR7A" />
					<input type="hidden" name="no_recurring" value="0" />
					<input type="hidden" name="currency_code" value="EUR" />
					<input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donate_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
				</form>
			<Text>Buy me seeds <br />🌱</Text>
		</Wrapper>
	)
}

export default Donate

const Wrapper = styled.div`
	position: fixed;
	display: ${props => props.active ? 'flex' : 'none'};
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 140px;
	height: 140px;
	padding: 10px;
	left: 0;
	bottom: 100px;
	background: white;
	opacity: 0.9;
	box-shadow: 1px 3px 3px -2px rgba(0,0,0,0.20);

	@media ${breakpoints.tabletS} {
		width: 200px;
	}
`

const CloseButton = styled.span`
	position: absolute;
	top: 2px;
	right: 2px;
	width: 15px;
	height: 15px;
	cursor: pointer;

	&:before,
	&:after {
		content: "";
    position: absolute;
    top: 9px;
    left: 0;
    right: 2px;
    height: 1px;
    background: #7e7e7e;
    border-radius: 4px;
	}

	&:before {
		transform: rotate(45deg);
	}

	&:after {
		transform: rotate(-45deg);
	}
`

const Text = styled.span`
	text-align: center;
`
