import { createGlobalStyle } from 'styled-components';

export const GlobalFonts = createGlobalStyle`
	@font-face {
		font-family: 'Oswald';
		src: url('/fonts/Oswald-ExtraLight.eot');
		src: url('/fonts/Oswald-ExtraLight.eot?#iefix') format('embedded-opentype'),
				url('/fonts/Oswald-ExtraLight.woff2') format('woff2'),
				url('/fonts/Oswald-ExtraLight.woff') format('woff');
		font-weight: 200;
		font-style: normal;
	}

	@font-face {
		font-family: 'Oswald';
		src: url('/fonts/Oswald-Light.eot');
		src: url('/fonts/Oswald-Light.eot?#iefix') format('embedded-opentype'),
				url('/fonts/Oswald-Light.woff2') format('woff2'),
				url('/fonts/Oswald-Light.woff') format('woff');
		font-weight: 300;
		font-style: normal;
	}

	@font-face {
		font-family: 'Oswald';
		src: url('/fonts/Oswald-Regular.eot');
		src: url('/fonts/Oswald-Regular.eot?#iefix') format('embedded-opentype'),
				url('/fonts/Oswald-Regular.woff2') format('woff2'),
				url('/fonts/Oswald-Regular.woff') format('woff');
		font-weight: 400;
		font-style: normal;
	}

	@font-face {
		font-family: 'Oswald';
		src: url('/fonts/Oswald-Medium.eot');
		src: url('/fonts/Oswald-Medium.eot?#iefix') format('embedded-opentype'),
				url('/fonts/Oswald-Medium.woff2') format('woff2'),
				url('/fonts/Oswald-Medium.woff') format('woff');
		font-weight: 500;
		font-style: normal;
	}
`
