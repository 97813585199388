import { useSelector, useDispatch } from 'react-redux'
import { navActions } from '../store/nav'
import { Link } from 'react-router-dom'

import datas from '../datas/navigation.json'

import { MenuStyles } from './MenuStyles'

const MenuScroll = ({ refs }) => {
  const dispatch = useDispatch()
  const active = useSelector(state => state.nav.active)

  const onScrollHandler = (e, i) => {
    refs.current[i].scrollIntoView({ behavior: 'smooth' })
    dispatch(navActions.toggle())
  }

  const onLinkHandler = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    dispatch(navActions.toggle())
  }

  return (
    <MenuStyles active={active}>
      {datas.map((page, i) => {
        return (
          <li
            onClick={(e) => onScrollHandler(e, i)}
            key={`page-${i}`}
          >
            {page.title}
            {page.subpages &&
            <ul>
              {page.subpages.map((subpage, i) => {
                return <li key={`subpage-${i}`}>
                  <Link to={`/${page.slug}/${subpage.slug}`} onClick={() => onLinkHandler}>{subpage.slug}</Link>
                </li>
              })}
            </ul>
            }
          </li>
        )
      })}
      <li>
        <Link to="/homepage" onClick={onLinkHandler} >Back to home</Link>
      </li>
    </MenuStyles>
  )
}

export default MenuScroll
