const sizes = {
	mobileS: '320px',
	mobileM: '375px',
	mobileL: '425px',
	tabletS: '480px',
	tablet: '768px',
	laptop: '1024px',
	laptopM: '1280px',
	laptopL: '1440px',
	laptopXL: '1920px',
	desktop: '2560px'
}

const breakpoints = {
	mobileS: `(min-width: ${sizes.mobileS})`,
	mobileM: `(min-width: ${sizes.mobileM})`,
	mobileL: `(min-width: ${sizes.mobileL})`,
	tabletS: `(min-width: ${sizes.tabletS})`,
	tablet: `(min-width: ${sizes.tablet})`,
	laptop: `(min-width: ${sizes.laptop})`,
	laptopM: `(min-width: ${sizes.laptopM})`,
	laptopL: `(min-width: ${sizes.laptopL})`,
	laptopXL: `(min-width: ${sizes.laptopXL})`,
	desktop: `(min-width: ${sizes.desktop})`,
	maxLandscape: `(orientation: landscape) and (max-height: 400px)`
}

export { sizes, breakpoints }
