import styled from 'styled-components'

import PageLayout from '../templates/PageLayout'
import TitleMain from '../components/TitleMain'
import ComingTitle from '../components/ComingTitle'


const AboutMe = (data) => {
  return (
    <PageLayout classes="about-me-page">
      <TitleMain>About me</TitleMain>
      <ComingTitle />
    </PageLayout>
  )
}

export default AboutMe
