import { useState, useRef, useEffect } from "react"
import styled, { keyframes } from "styled-components"
import Picture from '../components/Picture'

import * as animations from '../styles/animation'


const Slideshow = ({ images, curIndex}) => {
  const [index, setIndex] = useState(0)
  const [width, setWidth] = useState(0)
  const [transition, setTransition] = useState(false)
  const [xPosition, setXPosition] = useState(0);

  const slideRef = useRef();


  useEffect(() => {
  // Get and set a slide width
    const width = slideRef.current.clientWidth
    setWidth(width)
  })

  useEffect(() => {
    setWidth(width)
    setIndex(curIndex)
    setXPosition(-curIndex * width)
  }, [curIndex, width])

  const handleClickPrev = () => {
    if (index === 0) return
    setIndex(index - 1)
    setXPosition(xPosition + width)
    setTransition(true)
  }

  const handleClickNext = () => {
    if (index === images.length - 1) {
      setIndex(0)
      setXPosition(0)
    } else {
      setIndex(index + 1)
      setXPosition(xPosition - width)
      setTransition(true)
    }
  }
  return (
    <Wrapper>
      <div>
        <Slides xPosition={xPosition} effect={transition} className="slides">
          {images.map((image, i) => (
            <Slide ref={slideRef} className="slide">
                <img src={`${image.url}__1024.jpg`} alt="" key={i} />
            </Slide>
          ))}
        </Slides>
      </div>
      <>
        {index > 0 && <Button side="prev" onClick={handleClickPrev}>Back</Button>}
        {index < images.length - 1 && <Button side="next" onClick={handleClickNext}>Next</Button>}
      </>
    </Wrapper>
  )
}

export default Slideshow

const flash = keyframes`
	0% {
		opacity: .3;
	}
	100% {
		opacity: 1;
	}
`

const Wrapper = styled.div`
  position: relative;
  width: 1024px;
  max-width: 90%;
  max-height: 680px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({theme}) => theme.colors.black};
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  z-index: 999;
`

const Slides = styled.div`
  display: flex;
  transition: ${props => props.effect ? 'transform 0.6s ease-in-out' : ''};
  transform: ${(props) => `translateX(${props.xPosition}px)`};
`

const Slide = styled.div`
  display: inline;
  width: 100%;
  animation: ${flash} 1s;
  width: 1024px;

  img {
    width: 100%;
    height: auto;
    object-fit: contain;
  }
`

const Button = styled.button`
  position: absolute;
  padding: 1%;
  height: 100%;
  top: 50%;
  font-size: 1.25rem;
  transform: translateY(-50%);
  left: ${props => props.side === 'prev' && 0};
  right: ${props => props.side === 'next' && 0};
  color: white;
  cursor: pointer;
  transition: background 0.75s;
  z-index: 9999;

  &:hover {
    background: rgba(0,0,0, 0.4);
  }
`
