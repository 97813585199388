import { createSlice } from '@reduxjs/toolkit'

const initialNavState = { active: false, unactive: true }

const navSlice = createSlice({
	name: 'nav',
	initialState: initialNavState,
	reducers: {
		toggle(state) {
			state.active = !state.active
			state.unactive = !state.unactive
		}
	}
})

export const navActions = navSlice.actions

export default navSlice.reducer
