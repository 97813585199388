import { sizes } from '../styles/devices'

const Picture = ({ url }) => {
	if(!url) {
		return <img src="../assets/defaultImage.jpg" alt="Life digital vision" />
	}

	return (
		<picture>
			<source srcSet={`${url}__410-410.jpg`} media={`(max-width: ${sizes.mobileL})`} width="640" height="640" />
			<source srcSet={`${url}__640.jpg`} media="(max-width: 640px)" width="640" />
			<source srcSet={`${url}__1024.jpg`} media={`(max-width: ${sizes.laptop})`} width="1024" />
			<img src={`${url}__1024.jpg`} width="1024" alt="" />
		</picture>
		)
}

export default Picture
