import styled from 'styled-components'

import PageLayout from '../templates/PageLayout'
import TitleMain from '../components/TitleMain'
import ComingTitle from '../components/ComingTitle'

const Legacy = (data) => {

  return (
    <PageLayout classes="legacy-page">
      <TitleMain>Legacy</TitleMain>
      <ComingTitle />
    </PageLayout>
  )
}

export default Legacy
