import styled from 'styled-components'

const Footer = ( props ) => {
	return (
		<Wrapper>
			@ Life Digital Vision 2022 ~ Make Life Reborn 🌱
		</Wrapper>
		)
}

export default Footer

const Wrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
	background: black;
	min-height: 100px;
`
