import ReactDOM from 'react-dom'
import styled from 'styled-components'
import * as animations from '../styles/animation'

const Modal = ({ active, setActive, children }) => {

	const closeModal = () => {
		setActive(!active)
	}

	return ReactDOM.createPortal(
		<>
			<Wrapper active={active}>
				<InnerWrapper onClick={() => closeModal()} />
				<span onClick={() => closeModal()}></span>
				{children}
			</Wrapper>
		</>,
		document.getElementById("modal")
	)
}

export default Modal

const Wrapper = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	display: ${props => props.active ? 'flex': 'none'};
	align-items: center;
	width: 100%;
	height: 100%;
	color: white;
	background: rgba(0,0,0, 0.6);
	z-index: 999;

	span {
		position: fixed;
		margin: 10px;
		top: 60px;
		right: 0;
		width: 40px;
		height: 40px;
		display: block;
		cursor: pointer;

		&:before,
		&:after {
			content: '';
			position: absolute;
			top: 50%;
			margin-top: -2px;
			width: 40px;
			height: 3px;
			border-radius: 2px;
			background: #484848;
			transition: background 0.5s;
		}

		&:before {
			animation: ${animations.animationOne} 1s ease forwards;
		}
		&:after {
			animation: ${animations.animationTwo} 1s ease forwards;
		}

		&:hover {
			&:before,
			&:after {
				background: #9f9f9f;
			}
		}
	}
`

const InnerWrapper = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
`
