import { Link } from 'react-router-dom'
import styled from 'styled-components'

import datas from '../datas/navigation.json'
import { breakpoints } from '../styles/devices'

const MenuItem = ({ pageSlug }) => {
	const [earth] = datas

  return (
    <MenuStyles>
			{earth.subpages.map((subpage, index) => (
				<Item key={`${pageSlug}-${index}`} active={pageSlug === subpage.slug ? true : false}>
					<Link to={`/earth/${subpage.slug}`}>
						{subpage.title}
					</Link>
				</Item>
			))}
  	</MenuStyles>
  )
}

export default MenuItem

const MenuStyles = styled.ul`
		display: none;
		flex-wrap: wrap;
    justify-content: center;
		margin: 0 auto;

	@media ${breakpoints.tablet} {
		display: flex;
	}
`
const active = `background: rgb(0,212,255);
background: linear-gradient(90deg, rgba(0,212,255,1) 0%, rgba(104,245,242,1) 45%, rgba(135,236,118,1) 100%);`

const Item = styled.li`
	padding: 0 10px;
	color: ${props => props.active ? 'tomato': 'initial'};
`
