import styled from 'styled-components';
import Icon from './Icon'
import Logo from './Logo'

import Burger from './Burger'


const Header = ({ children }) => {
	return (
    <HeaderStyles>
      <Bar>
        <Icon />
        <Logo />
        <Burger />
      </Bar>
      { children }
    </HeaderStyles>
  )
}

export default Header


const HeaderStyles = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  font-family: 'Oswald-Light';
  z-index: 99999;
`
const Bar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0;
  font-family: 'oswald';
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 1px 9px 0px rgb(164 164 164 / 84%);
  font-family: 'Oswald-Light';
  color: black;
`
