import styled from 'styled-components'

const ComingTitle = () => {
	return<Text>Comming soon... 🌱 </Text>
}

const Text = styled.span`
	font-size: 1.5em;
	font-weight: 200;
`

export default ComingTitle
