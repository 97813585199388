import { useRef, useState } from 'react'

import Grid from './Grid'
import Modal from '../components/Modal'
import Slideshow from '../components/Slideshow'

const Photoviewer = ({ images, pageSlug }) => {
	const imagesRefs = useRef([])
	const [active, setActive] = useState(false)
	const [curIndex, setCurIndex] = useState(0)

	const onImageHandler = (index) => {
		setActive(!active)
		setCurIndex(index)
	}

	const defaultDescription = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'

	return (
		<>
			<Grid>
				{images.map((image, index) => (
					<div onClick={() => onImageHandler(index)} ref={(image) => imagesRefs.current[index] = image} key={`${pageSlug}-${index}`} className="box">
						<img src={`${image.url}__410-410.jpg`} alt="" />
						<div className="comment">
							{image.title && <h3>{image.title}</h3>}
							{image.description && <p>{image.description}</p>}
							{image.date && <span>{image.date}</span>}
							{image.time && <span>{image.time}</span>}
							<br />
							{image.credits && (
								<>
									<span className="label">Picture Credits</span>
									<span className="credit">{image.credits}</span>
								</>
							)}
						</div>
					</div>
				))}
			</Grid>
      {/* <Modal active={active} setActive={setActive}>
				<Slideshow images={images} curIndex={curIndex} />
      </Modal> */}
		</>
	)
}

export default Photoviewer
