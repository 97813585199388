import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { breakpoints } from '../styles/devices'

import Header from '../components/Header'
import MenuLink from '../components/MenuLink'
import MenuItem from '../components/MenuItem'
import TitleMain from '../components/TitleMain'
import Content from '../components/Content'
import PhotoViewer from '../components/PhotoViewer'
import Footer from '../components/Footer'

const Discover = ({ datas }) => {
  const params = useParams('country')
  const pageData = datas.filter((data) => data.slug === params.country)
  const { title, description, images} = pageData[0]

  return (
    <Wrapper>
      <Header>
        <MenuLink pageSlug={params.country} />
      </Header>
      <Content>
        <MenuItem pageSlug={params.country} />
        <TitleMain>{title}</TitleMain>
        <Text>{description}</Text>
        <PhotoViewer images={images} pageSlug={params.country} />
      </Content>
      <Footer />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  min-height: 100%;
  background-color: white;
`

const Text = styled.p`
  margin: 0 auto;
  font-size: 1.25rem;
  font-weight: 100;
  text-align: center;

  @media ${breakpoints.tablet} {
    width: 50%;
  }
`

export default Discover
