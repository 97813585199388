import { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import Svg from '../components/Svg'

import {
	Play as PlayIcon,
	Pause as PauseIcon,
	Speaker as SpeakerIcon,
	Mute as MuteIcon,
	Expand as ExpandIcon,
	Minimize as MinimizeIcon,
} from '../icons/index'

import InAndOutObserver from '../lib/InAndOutObserver'

const PlayerVideo = ({ src, poster, autoPlay, ...rest }) => {
	const [paused, setPaused] = useState(!autoPlay)
	const [muted, setMuted] = useState(true)
	const [fullScreen, setFullScreen] = useState(false)
	const wrapperRef = useRef(null)
	const videoRef = useRef(null)

	const toggleMute = () => {
		setMuted(!muted)
	}

	const play = () => {
		setPaused(false)
		videoRef.current.play()
	}

	const pause = () => {
		setPaused(true)
		videoRef.current.pause()
	}

	const togglePause = () => {
		if (paused) {
			play()
		} else {
			pause()
		}
	}

	const toggleFullScreen = () => {
		setFullScreen(!fullScreen)
		if (!fullScreen) {
			wrapperRef.current.requestFullscreen()
		} else {
			document.exitFullscreen()
		}
	}

	useEffect(() => {
		const onEnteringViewport = () => {
			if (autoPlay) {
				play()
			}
		}
		const onLeavingViewport = () => {
			pause()
		}

		const element = wrapperRef.current
		InAndOutObserver.get().observe(element, onEnteringViewport, onLeavingViewport)
		return () => {
			InAndOutObserver.get().unobserve(element)
		}
	}, [])

	return (
		<Wrapper ref={wrapperRef} {...rest}>
			{/* <Controller>
				<Button onClick={togglePause} title={paused ? 'play' : 'pause'}>
					{paused ? <Svg src={PlayIcon} /> : <Svg src={PauseIcon} />}
				</Button>
				<Button onClick={toggleMute} title={muted ? 'unmute' : 'mute'}>
					{muted ? <Svg src={MuteIcon} /> : <Svg src={SpeakerIcon} />}
				</Button>
				<Button onClick={toggleFullScreen} title={fullScreen ? 'exit-fullscreen' : 'fullscreen'}>
					{fullScreen ? <Svg src={MinimizeIcon} /> : <Svg src={ExpandIcon} />}
				</Button>
			</Controller>
			<Video src={src} poster={poster} autoPlay={autoPlay} muted={muted} loop ref={videoRef}></Video> */}
			<Video src={src} poster={poster} autoPlay={autoPlay} muted={muted} loop ref={videoRef}></Video>
		</Wrapper>
	)
}

PlayerVideo.propTypes = {
	src: PropTypes.string,
	poster: PropTypes.string,
	autoPlay: PropTypes.bool,
}

export default PlayerVideo


const Wrapper = styled.div`
	position: relative;
	width: 100%;
	height: ${(props) => props.height ? props.height : '100vh'};
	overflow: hidden;
`

const Video = styled.video`
	width: 100%;
	height: 100%;
	object-fit: cover;
`

const Controller = styled.div`
	position: absolute;
	bottom: 0;
	right: 0;
	padding: ${({ theme }) => theme.spaces.xxs};
	background: white;
	opacity: .4;
	border-radius: 10px 0 0 0;
	font-size: 1.75rem;
	z-index: 2;
	&:hover {
		opacity: .6;
		transition: opacity 1s ease-out;
	}
`

const Button = styled.button`
	margin-left: 0.5em;
	color: ${({ theme }) => theme.colors.white};
	opacity: 0.7;
	outline: none;

	& svg {
		display: block;
		width: 1em;
		height: 1em;
	}

	&:hover {
		color: white;
		transition: opacity 0.5s ease;
		opacity: 1;
	}
`

// return (
// 	<Wrapper ref={wrapperRef} {...rest}>
// 		<Controller>
// 			<Button onClick={togglePause} title={paused ? t('play') : t('pause')}>
// 				{paused ? <PlayIcon /> : <PauseIcon />}
// 			</Button>
// 			<Button onClick={toggleMute} title={muted ? t('unmute') : t('mute')}>
// 				{muted ? <MuteIcon /> : <SpeakerIcon />}
// 			</Button>
// 			<Button onClick={toggleFullScreen} title={fullScreen ? t('exit-fullscreen') : t('fullscreen')}>
// 				{fullScreen ? <MinimizeIcon /> : <ExpandIcon />}
// 			</Button>
// 		</Controller>
// 		<Video src={src} poster={poster} autoPlay={autoPlay} muted={muted} loop ref={videoRef}></Video>
// 	</Wrapper>
// )
