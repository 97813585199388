import styled from 'styled-components'
import { breakpoints } from '../styles/devices'

const Content = ({ children, withPadding = true}) => {
  return (
    <ContentStyles withPadding={withPadding}>
      {children}
    </ContentStyles>
  )
}

export default Content

const ContentStyles = styled.div`
  min-height: 90vh;
  margin: 0 auto;
  text-align: center;
  padding-top: ${props => props.withPadding ? '100px' : '0'};
  padding-bottom: 100px;
  padding-left: 1rem;
  padding-right: 1rem;

  @media ${breakpoints.laptop} {
    max-width: 1280px;
  }
`
