import styled from 'styled-components'

import Header from '../components/Header'
import MenuLink from '../components/MenuLink'
import Content from '../components/Content'
import Footer from '../components/Footer'

const PageLayout = ({ children, classes = ''}) => {
  return (
    <div className={classes}>
      <Header>
        <MenuLink />
      </Header>
      <Content>
        {!children ? <Text>Coming soon...</Text> : children}
      </Content>
      <Footer />
    </div>
  )
}

const Text = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 50vh;
  font-size: 1.5rem;
`

export default PageLayout
