import { Routes, Route, Navigate } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'

import DATAS_HOMEPAGE from './datas/homepage.json'
import DATAS_EARTH from './datas/earth.json'

import AboutMe from './pages/AboutMe'
import Legacy from './pages/Legacy'
import Discover from './pages/Discover'

import { GlobalStyles } from './styles/global'
import { GlobalFonts } from './styles/font'
import { theme } from './styles/theme'

import Homepage from './pages/Homepage'
import Earthpage from './pages/Earth'

import Donate from './components/Donate'

const App = () => {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <GlobalFonts />
        <GlobalStyles />
        <Routes>
          <Route path="/" element={<Navigate to="/homepage" />} />
          <Route path="/homepage" element={<Homepage datas={DATAS_HOMEPAGE} classes="homepage" />} />
          <Route path="/earth" element={<Earthpage datas={DATAS_EARTH} classes="earth-page"/>} />
          <Route path="/cv" element={<Earthpage datas={DATAS_EARTH} classes="cv-page"/>} />
          <Route
            path="/earth/:country"
            element={<Discover datas={DATAS_EARTH} />}
          />
          <Route path="/legacy" element={<Legacy />} />
          <Route path="/about-me" element={<AboutMe />} />
        </Routes>
      </ThemeProvider>
      {/* <Donate /> */}
    </div>
  )
}

export default App
