import Picture from '../components/Picture'

import styled from 'styled-components'
import { breakpoints } from '../styles/devices'

const Card = ({ card, right }) => {
  const { title_nav, description, credits, cover, date, time } = card
  return (
    <CardStyles className="card" right={right}>
      <ImageWrapper>
        <Picture url={cover.url} />
      </ImageWrapper>
      <Description right={right}>
        <h2>{title_nav}</h2>
        <p>{description}</p>
        <p>{credits}</p>
      </Description>
    </CardStyles>
  )
}

const CardStyles = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  @media ${breakpoints.tablet} {
    padding: 3rem 2rem;
    flex-direction: ${({ right }) => (right ? 'row-reverse' : 'row')};
  }
`

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  overflow: hidden;
  max-width: 1024px;
`

const Description = styled.div`
  padding: 1rem;
  background-color: rgba(255, 255, 255, 0.6);

  h2 {
    font-size: 1.5rem;
    text-transform: uppercase;
    text-align: center;
  }

  p {
    text-align: justify;
  }

  @media ${breakpoints.tablet} {
    position: absolute;
    top: 20%;
    right: 0;
    width: 360px;
    padding: 25px 25px 25px 30px;
    box-shadow: 1px 3px 3px -2px rgba(0,0,0,0.20);
    background: rgba(255, 255, 255, 0.9);

    ${(props) => props.right && ` left: 0;`}}
`

export default Card
