import { createGlobalStyle } from 'styled-components'
// import { GlobalFonts } from './font'

export const GlobalStyles = createGlobalStyle`
	/* Global fonts */
	@font-face {
		font-family: 'Oswald';
		src: url('/fonts/Oswald-ExtraLight.eot');
		src: url('/fonts/Oswald-ExtraLight.eot?#iefix') format('embedded-opentype'),
				url('/fonts/Oswald-ExtraLight.woff2') format('woff2'),
				url('/fonts/Oswald-ExtraLight.woff') format('woff');
		font-weight: 200;
		font-style: normal;
	}

	@font-face {
		font-family: 'Oswald';
		src: url('/fonts/Oswald.eot');
		src: url('/fonts/Oswald.eot?#iefix') format('embedded-opentype'),
				url('/fonts/Oswald.woff2') format('woff2'),
				url('/fonts/Oswald.woff') format('woff');
		font-weight: 300;
		font-style: normal;
	}

	@font-face {
		font-family: 'Oswald';
		src: url('/fonts/Oswald-Regular.eot');
		src: url('/fonts/Oswald-Regular.eot?#iefix') format('embedded-opentype'),
				url('/fonts/Oswald-Regular.woff2') format('woff2'),
				url('/fonts/Oswald-Regular.woff') format('woff');
		font-weight: 400;
		font-style: normal;
	}

	@font-face {
		font-family: 'Oswald';
		src: url('/fonts/Oswald-Medium.eot');
		src: url('/fonts/Oswald-Medium.eot?#iefix') format('embedded-opentype'),
				url('/fonts/Oswald-Medium.woff2') format('woff2'),
				url('/fonts/Oswald-Medium.woff') format('woff');
		font-weight: 500;
		font-style: normal;
	}

	/* Global reset and set default*/
	body {
		min-width: 320px;
		height: 100%;
		margin: 0;
		font-size: 16px;
	}

	* {
		box-sizing: border-box;
		font-family: 'Oswald', Verdana, sans‑serif;
		font-weight: 300;
	}

	body > * {
		height: 100%;
		white-space: pre-line;
	}

	main {
		display: block;
	}

	a {
		background-color: transparent;
		color: inherit;
		text-decoration: none;
		cursor: pointer;
	}

	h1 {
		font-size: 2em;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin: 0;
		padding: 0;
		font-size: inherit;
		font-weight: inherit;
		font-family: 'Oswald', DejaVu Sans, Verdana, sans‑serif;
	}

	p {
		margin: 0;
	}

	hr {
		box-sizing: content-box;
		height: 0;
		overflow: visible;
		border: none;
		border-bottom: 1px solid #231f20;
	}

	ul,
	ol,
	dd {
		margin: 0;
		padding: 0;
		list-style: none;
	}

	pre {
		font-family: monospace;
		font-size: 1em;
	}

	figure {
		margin: 0;
	}

	abbr[title] {
		border-bottom: none;
		text-decoration: underline;
		text-decoration: underline dotted;
	}

	b,
	strong {
		font-weight: 500;
	}

	code,
	kbd,
	samp {
		font-family: monospace;
		font-size: 1em;
	}

	small {
		font-size: 80%;
	}

	sub,
	sup {
		font-size: 75%;
		line-height: 0;
		position: relative;
		vertical-align: baseline;
	}

	sub {
		bottom: -0.25em;
	}

	sup {
		top: -0.5em;
	}

	img {
		width: 100%;
		height: auto;
		border-style: none;
	}

	button,
	input,
	optgroup,
	select,
	textarea {
		margin: 0;
		line-height: 1.15;
		font-size: 100%;
		font-family: inherit;
		font-weight: inherit;
	}

	button {
		padding: 0;
		border-width: 0;
		background-color: transparent;
		color: inherit;
		cursor: pointer;
	}

	button,
	input {
		overflow: visible;
	}

	button,
	select {
		text-transform: none;
	}

	button,
	[type='button'],
	[type='reset'],
	[type='submit'] {
		-webkit-appearance: button;
	}

	button::-moz-focus-inner,
	[type='button']::-moz-focus-inner,
	[type='reset']::-moz-focus-inner,
	[type='submit']::-moz-focus-inner {
		border-style: none;
		padding: 0;
	}

	button:-moz-focusring,
	[type='button']:-moz-focusring,
	[type='reset']:-moz-focusring,
	[type='submit']:-moz-focusring {
		outline: 1px dotted ButtonText;
	}

	fieldset {
		border-width: 0;
		padding: 0;
		margin: 0;
	}

	cite {
		font-style: normal;
	}

	legend {
		display: table;
		max-width: 100%;
		padding: 0;
		color: inherit;
		white-space: normal;
	}

	progress {
		vertical-align: baseline;
	}

	textarea {
		overflow: auto;
	}

	[type='checkbox'],
	[type='radio'] {
		padding: 0;
	}

	[type='number']::-webkit-inner-spin-button,
	[type='number']::-webkit-outer-spin-button {
		height: auto;
	}

	[type='search'] {
		-webkit-appearance: textfield;
		outline-offset: -2px;
	}

	[type='search']::-webkit-search-decoration {
		-webkit-appearance: none;
	}

	::-webkit-file-upload-button {
		-webkit-appearance: button;
		font: inherit;
	}

	// Other reset
	*, *::after, *::before {
    box-sizing: border-box;
  }
`
