import PageScroll from '../templates/PageScroll'
import PlayerVideo from '../components/playerVideo'

const Homepage = ({ datas }) => {
  return (
    <div className="homepage">
      <PlayerVideo src="./videos/welcome.mp4" poster="./videos/cover.jpg" height="80vh" />
      {/* @TODO Make the video work with youtube link */}
      {/* <PlayerVideo src="youtu.be/tO1VLpVpu1g" poster="./videos/cover.jpg" autoPlay /> */}
      <PageScroll datas= {datas} withPadding={false} />
    </div>
  )
}

export default Homepage

// "description": "Between gastronomy, culture, history, seas, mountains and lands, France sees itself as a jewel in the center of Europe",
