let instance = null

export default class InAndOutObserver {
	static get () {
		if (instance) {
			return instance
		}

		instance = new InAndOutObserver()
		return instance
	}

	constructor () {
		const onIntersecting = entries => this.onIntersecting(entries)
		const options = {
			root: document.querySelector('[data-scroll]') || null,
			rootMargin: `0px 0px 0px 0px`,
			thresholds: [0]
		}

		this.observer = new IntersectionObserver(onIntersecting, options)
		this.callbacks = new Map()
	}

	observe (element, onEntering, onLeaving) {
		this.callbacks.set(element, { onEntering, onLeaving })
		this.observer.observe(element)
	}

	unobserve (element) {
		this.callbacks.delete(element)
		this.observer.unobserve(element)
	}

	call (callback) {
		if (callback && typeof callback === 'function') {
			callback()
		}
	}

	onIntersecting (entries) {
		entries.forEach(entry => {
			const { onEntering, onLeaving } = this.callbacks.get(entry.target)
			if (entry.isIntersecting) {
				this.call(onEntering)
			} else {
				this.call(onLeaving)
			}
		})
	}
}
