import styled from 'styled-components'
import PageScroll from '../templates/PageScroll'

const Earth = ({ datas }) => {
  return (
    <PageScroll datas= {datas} classes="earth-page"><More>More to discover...</More></PageScroll>
  )
}

const More = styled.div`
  height: 15vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 100;
`

export default Earth
